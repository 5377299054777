export enum ErrorCode{
    MBE_PROJECT_NOT_FOUND = 1,
    MBE_PROJECT_ALREADY_CONNECTED = 2,
    MBC_PROJECT_NOT_FOUND = 11,
    MBP_PROJECT_NOT_FOUND = 21,
    MBP_PROJECT_INACTIVE = 22,
    MBP_PROJECT_DOORS_NOT_SYNCED = 23,
    USER_NOT_MBE_ADMIN = 41,
    USER_NOT_MBP_ADMIN = 42,
    USER_NOT_MBM_ADMIN = 43,
    USER_NOT_MBE_ADMIN_DELETE_PROJECT = 44,
    USER_NOT_ADMIN_RENAME_PROJECT = 45,
    MBM_PROJECT_NOT_FOUND = 51,
    MBM_PROJECT_INACTIVE = 52,
    MBM_PROJECT_NO_WORK_ORDER = 53,
    MBC_REPLACE_PDF_WRONG_RATIO = 61,
    MBC_DOORNODE_ALREADY_EXISTS = 71,
    MBC_DOORNODE_NOT_FOUND = 72,
    CODE_NULL_OR_EMPTY = 81,
    MBC_DOCUMENT_ALREADY_EXISTS = 91,
    MBC_DOCUMENT_ALREADY_ATTACHED = 92,
    MBC_DOOR_MAX_ALLOWED_IMAGE_COUNT = 101,
    MBE_PROJECT_DOOR_NOT_FOUND = 3,
}