import { Injectable } from "@angular/core";
import { MBCSettings } from 'mbc-settings';

@Injectable()
export class ImageUtil {

    resizeBase64Image(base64Image: string, afterResized: (base64Image: string) => void) {
        const image = new Image();
        image.src = `data:image/jpeg;base64,${base64Image}`;
        image.onload = () => { 
            const resizedBase64Image: string = this.resizeImageToBase64(image);
            afterResized(resizedBase64Image);
        }
    }

    private resizeImageToBase64(image: HTMLImageElement): string {
        let canvas: HTMLCanvasElement = document.createElement('canvas');
        const context: CanvasRenderingContext2D = canvas.getContext('2d');
        if (context) {
            let maxWidth: number = MBCSettings.imageResizeMaxWidth;
            let maxHeight: number = MBCSettings.imageResizeMaxHeight;
            const {width, height} = this.calculateResizeRatio(image.width, image.height, maxWidth, maxHeight);
            
            canvas.width = width;
            canvas.height = height;
            context.drawImage(image, 0, 0, width, height);
            const imageURL: string = canvas.toDataURL('image/jpeg');
            return this.convertImageURLToBase64(imageURL);;
        }
        return '';
    }

    private calculateResizeRatio(
        width: number, height: number, maxWidth: number, maxHeight: number): { width: number, height: number } {
            if(width > maxWidth && maxWidth !== 0){
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }

            if(height > maxHeight && maxHeight !== 0){
                height = maxHeight;
                width = Math.round((width * maxHeight) / height);
            }
            return { width, height };
    }

    private convertImageURLToBase64(imageURL: string): string{
        const imagePattern = new RegExp(`^data:image\/(${MBCSettings.cameraSupportedFileTypes});base64,`);
        return imageURL.replace(imagePattern, '');
    }

    //all demo code for resizing image on client side
    demoResizeImageURL(imageURL: string, size: number, afterResized: (result: {base64Image: string, width: number, height: number}) => void) {
        const image = new Image();
        image.src = imageURL;
        image.onload = () => { 
            const resizedBase64Image: string = this.demoResizeImageToBase64(image, size);
            const result = {
                base64Image: resizedBase64Image,
                width: image.width,
                height: image.height
              };
            afterResized(result);
        }
    }

    private demoResizeImageToBase64(image: HTMLImageElement, size: number): string {
        let canvas: HTMLCanvasElement = document.createElement('canvas');
        const context: CanvasRenderingContext2D = canvas.getContext('2d');
        if (context) {
            let maxWidth: number = size;//MBCSettings.imageResizeMaxWidth;
            let maxHeight: number = 0;//MBCSettings.imageResizeMaxHeight;
            const {width, height} = this.calculateResizeRatio(image.width, image.height, maxWidth, maxHeight);
            
            canvas.width = width;
            canvas.height = height;
            console.log(`Resized image Width: ${width}, Height: ${height}`);
            context.drawImage(image, 0, 0, width, height);
            const imageURL: string = canvas.toDataURL('image/png');
            return this.convertImageURLToBase64(imageURL);
        }
        return '';
    }

    calculateBase64Size(base64String: string): number {
        let padding = 0;
        if (base64String.endsWith('==')) padding = 2;
        else if (base64String.endsWith('=')) padding = 1;
    
        const base64Length = base64String.length;
        const sizeInBytes = (base64Length * 3) / 4 - padding;
        const sizeInMB = sizeInBytes / (1024 * 1024);
        return sizeInMB;
    }
    ////////////////////////////////////////////////
}