import { Injectable } from '@angular/core';
import { DoorNote, DoorImage } from 'mbc-models';
import { HttpService } from 'mbc-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DoorNotesService {

  constructor(
    private httpService: HttpService) {
  }

  public getDoorNoteByDoorId(projectId: string, doorId: string): Observable<DoorNote> {
    return this.httpService.getObject<DoorNote>(`projects/${projectId}/doors/${doorId}/notes`);
  }

  public attachImage(projectId: string, doorId: string, base64Image: string): Observable<DoorImage>{
    return this.httpService
      .sendObjects<any>(`projects/${projectId}/doors/${doorId}/notes/images`, { base64Image: base64Image });
  }

  public updateImage(projectId: string, doorId: string, imageId: string, base64Image: string): Observable<DoorImage>{
    return this.httpService
      .patchAndGetObjects<DoorImage, any>(`projects/${projectId}/doors/${doorId}/notes/images/${imageId}`, { base64Image: base64Image });
  }

  public deleteImage(projectId: string, doorId: string, imageId: string): Observable<DoorImage>{
    return this.httpService
      .deleteObject<DoorImage>(`projects/${projectId}/doors/${doorId}/notes/images/${imageId}`);
  }
}
