import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageService } from 'mbc-services';
import { MBCSettings } from 'mbc-settings';
import { ImageUtil } from './image-util';

@Injectable()
export class CameraUtil {

    constructor(
        private translate: TranslateService,  
        private errorMessageService: ErrorMessageService, 
        private imageUtil: ImageUtil) { }

    async takePhotoToBase64Image(onComplete: (imageURL: string) => void) {
        await Camera.getPhoto({
            quality: 50,
            allowEditing: true,
            resultType: CameraResultType.Base64,
            source: CameraSource.Camera
        }).then(async (photo) => {
            if (this.isSupportedPhoto(photo)) {
                this.imageUtil.resizeBase64Image(photo.base64String, (resizedBase64Image) => {
                    onComplete(resizedBase64Image);
                });
            }
        }, (rejectedReason) => {
            //handling camera cancelation
        });
    }

    private isSupportedPhoto(photo: Photo): boolean {
        let supportedFileTypes: string[] = MBCSettings.cameraSupportedFileTypes.split('|');

        if(!supportedFileTypes.includes(photo.format)){
            this.errorMessageService.displayErrorMessage(
                this.translate.instant('mbcDoorNotesPanel.component.fileValidateError.header'),
                this.translate.instant(
                    'mbcDoorNotesPanel.component.fileValidateError.supportedFileTypeMessage', 
                    { fileType: supportedFileTypes.join(', ') }),
              );
            return false;
        }
        return true;
    }
}